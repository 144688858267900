<template>
  <div class="pb-16">
    <el-card shadow="never">
      <div class="flex ju-be">
        <h3 class="size-20">ZINKON SVMDCC 激光测量仪</h3>
        <div class="">
          <el-button type="primary">可视化编辑</el-button>
          <el-button type="primary">离线下载</el-button>
          <el-button type="primary">预览</el-button>
          <el-button type="primary">保存</el-button>
        </div>
      </div>
    </el-card>
    <el-card shadow="never" class="mt-16">
      <div class="flex ju-be">
        <div class="flex">
          <div
            class="img-box-hover"
            @mousemove="mousemoveHead"
            @mouseout="mouseout"
          >
            <img
              class="w-240 h-148 ra-6"
              src="@/assets/images/image1.png"
              alt=""
            />
            <transition name="el-fade-in-linear">
              <div class="hover-box" v-if="showHead">
                <p>更换封面</p>
              </div>
            </transition>
          </div>
          <div class="flex-clm ju-be h-148 ag-str ml-35">
            <el-form label-width="78px" :model="ruleForm">
              <el-form-item label="项目标题" prop="title">
                <el-input
                  v-model="ruleForm.title"
                  placeholder="请输入项目标题"
                ></el-input>
              </el-form-item>
              <el-form-item label="项目分类" prop="title">
                <el-input
                  v-model="ruleForm.title"
                  placeholder="请输入项目标题"
                ></el-input>
              </el-form-item>
              <el-form-item label="项目介绍" prop="title">
                <el-input
                  v-model="ruleForm.title"
                  placeholder="请输入介绍"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <img class="w-130 h-130" src="@/assets/images/qr-img.png" alt="" />
          <p class="size-12 text-center">手机扫码体验</p>
        </div>
      </div>

      <div class="xian mtb-16"></div>
      <p class="size-14 co-33 pl-13 ptb-12" style="background: #f7fafa">
        全景场景
      </p>
      <div class="flex mt-12">
        <div v-for="(i, n) in 3" :key="n">
          <div class="list-item mr-16">
            <img
              class="w-240 h-148 ra-6"
              src="@/assets/images/image4.png"
              alt=""
            />
            <div class="text flex ju-be">
              <p>M14 - 副本 (2)</p>
              <p class="pointer">删除</p>
            </div>
          </div>
        </div>
        <div class="upload-box">
          <p>点击从素材库添加</p>
        </div>
      </div>
      <div class="xian mtb-16"></div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="功能启用" name="first">
          <el-table :data="functionList" style="width: 100%" class="mt-20">
            <el-table-column align="center" type="index" label="序号" />
            <el-table-column align="center" prop="name" label="功能启用">
            </el-table-column>
            <el-table-column prop="text" label="功能描述" width="600px">
            </el-table-column>
            <el-table-column label="启用状态" align="center">
              <div slot-scope="scope">
                <el-switch
                  v-model="scope.row.type"
                  active-color="$color-1"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="全景设置" name="second"> 
          <el-table :data="setList" style="width: 100%" class="mt-20">
            <el-table-column align="center" type="index" label="序号" />
            <el-table-column align="center" prop="name" label="功能启用">
            </el-table-column>
            <el-table-column prop="text" label="功能描述" width="600px">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <div slot-scope="scope">
                <el-button type="text" @click="opeEdit(scope.row.id)">编辑</el-button>
              </div>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
      :title="setList[setIndex - 1].name"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <dialog-Content :type="setIndex"></dialog-Content>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dialogContent from "./components/dialogContent.vue";
export default {
  components: {
    dialogContent,
  },
  data() {
    return {
      activeName: "first",
      dialogVisible: false,
      showHead: false,
      functionList: [
        // {
        //   name: "发布全景",
        //   type: true,
        //   text: "若不想作品被浏览，可选择将作品设置为未发布状态",
        // },
        {
          name: "场景列表",
          type: true,
          text: "设置是否自动打开全景列表",
        },
        {
          name: "自动旋转",
          type: true,
          text: "全景会自动旋转",
        },
        {
          name: "小行星开场",
          type: true,
          text: "开场时以小行星视角进入",
        },
        {
          name: "开启陀螺仪",
          type: true,
          text: "开启/关闭陀螺仪",
        },
        {
          name: "显示说一说",
          type: true,
          text: "打开全景时默认显示说一说",
        },
        {
          name: "开启足迹",
          type: true,
          text: "是否显示足迹",
        },
        {
          name: "隐藏作者姓名",
          type: true,
          text: "隐藏作者姓名",
        },
        {
          name: "隐藏LOGO",
          type: true,
          text: "隐藏LOGO",
        },
        {
          name: "隐藏人气",
          type: true,
          text: "隐藏人气",
        },
        {
          name: "隐藏VR眼镜",
          type: true,
          text: "隐藏VR眼镜",
        },
        {
          name: "隐藏作品简介",
          type: true,
          text: "在全景页显示项目简介",
        },
        {
          name: "隐藏点赞",
          type: true,
          text: "在全景页显示点赞",
        },
        {
          name: "隐藏分享",
          type: true,
          text: "在全景页显示分享",
        },
        {
          name: "允许推荐",
          type: true,
          text: "是否允许管理员将你的作品推荐到首页",
        },
      ],
      setIndex: 1,
      setList: [
        {
          name: "背景音乐",
          text: "为全景加入背景音乐",
          id: 1,
        },
        {
          name: "语音解说",
          text: "为全景加入语音解说",
          id: 2,
        },
        {
          name: "按钮设置",
          text: "全景中加入站外链接、电话号码或导航",
          id: 3,
        },
        {
          name: "底部菜单",
          text: "全景底部加入介绍菜单",
          id: 4,
        },
        {
          name: "补天补地",
          text: "为天空/地面增加一张遮罩图片，可以加入自定义信息或用来遮住脚架",
          id: 5,
        },
        {
          name: "开场提示",
          text: "设置进入全景时显示得提示信息",
          id: 6,
        },
        {
          name: "自定义右键",
          text: "右键菜单加入站外链接、电话号码",
          id: 7,
        },
        {
          name: "自定义LOGO",
          text: "为全景加入左上角自定义LOGO",
          id: 8,
        },
        {
          name: "自定义作者名",
          text: "为全景加入左上角自定义作者名",
          id: 9,
        },
        {
          name: "密码访问",
          text: "设置输入密码才能访问项目",
          id: 10,
        },
        {
          name: "顶部广告",
          text: "为全景加入顶部广告语",
          id: 11,
        },
        {
          name: "企业名片",
          text: "企业名片",
          id: 12,
        },
        {
          name: "加载提示",
          text: "全景加载时得提示语",
          id: 13,
        },
        {
          name: "启动画面",
          text: "启动画面",
          id: 14,
        },
        {
          name: "开场动画",
          text: "开场动画",
          id: 15,
        },
      ],
      ruleForm: {},
      rules: {},
    };
  },
  methods: {
    opeEdit(id) {
      this.setIndex = id;
      this.dialogVisible = true;
    },
    mousemoveHead() {
      this.showHead = true;
    },
    mouseout() {
      this.showHead = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  width: 500px;
  .el-form-item {
    margin-bottom: 10px;
  }
}
.img-box-hover {
  position: relative;
  .hover-box {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
  }
}
.dialog-bg {
  width: 100%;
  background: #f7fafa;
}
.list-item {
  position: relative;
  .text {
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    padding: 5px 8px;
  }
}
.upload-box {
  width: 240px;
  height: 148px;
  border: 1px dashed #999999;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 12px;
    color: #999999;
  }
}
</style>