<template>
  <div>
    <div v-if="type == 1">
      <div class="dialog-bg plr-10 ptb-8">
        <el-radio v-model="radio" label="1">全局背景音乐</el-radio>
        <el-radio v-model="radio" label="2">单个场景背景音乐</el-radio>
      </div>
      <div class="dialog-bg mt-16 plr-32 ptb-16" v-if="radio == 1">
        <div class="flex ju-be">
          <p>张茜-用力活着</p>
          <div class="flex">
            <p class="pointer txt-udl co-66">个人媒体库音乐</p>
            <p class="pointer txt-udl co-66 ml-16">系统音乐</p>
          </div>
          <div>
            <el-button type="primary">试听</el-button>
            <el-button type="primary">移除</el-button>
          </div>
        </div>
      </div>
      <div class="dialog-bg mt-16 plr-32 ptb-16" v-else>
        <div class="flex ju-be">
          <div class="flex">
            <img
              class="w-56 h-56 ra-4"
              src="@/assets/images/image4.png"
              alt=""
            />
            <p class="ml-16">测试项目1</p>
          </div>
          <p>张茜-用力活着</p>
          <div class="flex">
            <p class="pointer txt-udl co-66">个人媒体库音乐</p>
            <p class="pointer txt-udl co-66 ml-16">系统音乐</p>
          </div>
          <div>
            <el-button type="primary">试听</el-button>
            <el-button type="primary">移除</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type == 2">
      <div class="dialog-bg plr-10 ptb-8">
        <el-radio v-model="radio" label="1">全局语音解说</el-radio>
        <el-radio v-model="radio" label="2">单个场景语音解说</el-radio>
      </div>
      <div class="dialog-bg mt-16 plr-32 ptb-16" v-if="radio == 1">
        <div class="flex ju-be">
          <p>张茜-用力活着</p>
          <div class="flex">
            <p class="pointer txt-udl co-66">从媒体库选择音乐</p>
          </div>
          <div>
            <el-button type="primary">试听</el-button>
            <el-button type="primary">移除</el-button>
          </div>
        </div>
      </div>
      <div class="dialog-bg mt-16 plr-32 ptb-16" v-else>
        <div class="flex ju-be">
          <div class="flex">
            <img
              class="w-56 h-56 ra-4"
              src="@/assets/images/image4.png"
              alt=""
            />
            <p class="ml-16">测试项目1</p>
          </div>
          <p>张茜-用力活着</p>
          <div class="flex">
            <p class="pointer txt-udl co-66">从媒体库选择音乐</p>
          </div>
          <div>
            <el-button type="primary">试听</el-button>
            <el-button type="primary">移除</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type == 3">
      <div>
        <el-button type="primary">添加网站链接（电话号码）</el-button>
        <el-button type="primary">添加地图导航</el-button>
        <span class="szie-12 ml-6"
          >最多可定义五个网站地址（或电话号码、地图导航）</span
        >
      </div>
      <div>
        <div class="dialog-bg mt-16 plr-32 ptb-16 flex ju-be">
          <div class="flex">
            <div class="flex w-200">
              <img class="w-56 h-56" src="@/assets/images/logo.png" alt="" />
              <div class="ml-16">
                <p class="pointer size-14">系统图标</p>
                <p class="pointer size-14 mt-16">媒体库图标</p>
              </div>
            </div>
            <div class="flex">
              <el-select v-model="selectValue" placeholder="请选择">
                <el-option key="1" label="电话" value="1"> </el-option>
                <el-option key="2" label="网站链接" value="2"> </el-option>
              </el-select>
              <el-input
                class="ml-16"
                :placeholder="
                  selectValue == 1 ? '请输入电话号码' : '请输入网站链接'
                "
              ></el-input>
            </div>
          </div>
          <el-button type="primary">删除</el-button>
        </div>
      </div>
    </div>
    <div v-if="type == 5">
      <div class="dialog-bg ptb-12 plr-16">
        <el-radio v-model="radio" label="1">全局设置</el-radio>
        <el-radio v-model="radio" label="2">单场设置</el-radio>
      </div>
      <div class="dialog-bg ptb-12 plr-16 mt-32">
        <div class="flex">
          <div style="width: 50%">
            <el-radio v-model="radio" label="1">不使用遮罩 </el-radio>
            <el-radio v-model="radio" label="2">使用近迁全景遮罩</el-radio>
          </div>
          <div class="flex" style="width: 50%">
            <el-button type="primary">从媒体库选择图片</el-button>
            <img
              class="w-72 h-72 ra-4 ml-32"
              src="@/assets/images/logo.png"
              alt=""
            />
            <div class="size-14 ml-16">
              <p class="fw-7 co-33">近迁全景补地</p>
              <div class="mt-16">
                <el-radio v-model="radio" label="1">补天 </el-radio>
                <el-radio v-model="radio" label="2">补地</el-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type == 6">
      <div class="flex">
        <div style="width: 50%">
          <el-radio v-model="radio" label="1">不使用提示</el-radio>
          <el-radio v-model="radio" label="2">使用默认提示</el-radio>
        </div>
        <div class="flex" style="width: 50%">
          <el-button type="primary">从媒体库选择图片</el-button>
          <img
            class="w-72 h-72 ra-4 ml-32"
            src="@/assets/images/logo.png"
            alt=""
          />
          <p class="size-14 co-99 ml-12">默认提示</p>
        </div>
      </div>
    </div>
    <div v-if="type == 7">
      <div class="flex">
        <el-button type="primary" @click="addList7"
          >添加网站链接（或电话号码）</el-button
        >
        <p class="co-66 size-14 ml-16">可自定义三个网站地址或电话号码</p>
      </div>
      <div class="dialog-bg ptb-12 flex mt-24" v-for="(i, n) in list7" :key="n">
        <el-input
          v-model="i.a1"
          class="w-300"
          placeholder="输入名称"
        ></el-input>
        <el-input
          v-model="i.a2"
          class="w-300 ml-24"
          placeholder="输入链接地址或电话"
        ></el-input>
        <el-button class="ml-24" type="primary" @click="removeList7(n)"
          >删除</el-button
        >
      </div>
    </div>
    <div v-if="type == 8">
      <p class="text-center mtb-16 size-12">建议图片大小：120 x 120</p>
      <div class="flex flex-center">
        <img
          src="@/assets/images/image4.png"
          alt=""
          style="max-width: 300px; height: auto"
        />
      </div>
      <div class="flex flex-center mt-16">
        <el-button type="primary">从媒体库选择</el-button>
      </div>
      <div class="plr-32 mt-32">
        <el-form label-width="120px">
          <el-form-item label="链接地址：">
            <el-input placeholder="请输入链接地址"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="type == 9">
      <el-form label-width="120px">
        <el-form-item label="作者姓名：">
          <el-input placeholder="请输入作者姓名"></el-input>
          <p>作者名称不能超过20个字符</p>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="type == 10" class="plr-64">
      <p class="ptb-32">请输入3到20位访问密码：</p>
      <el-input placeholder="只能输入英文和数字"></el-input>
    </div>
    <div v-if="type == 11">
      <div class="plr-64">
        <el-form label-width="120px">
          <el-form-item label="内容">
            <el-input placeholder="请输入内容"></el-input>
            <p>广告语不能超过255个字符,若不想使用该功能，请保持内容为空</p>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="checked">允许显示系统广告</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="type == 12">
      <div class="flex">
        <div class="pr-32" style="width: 50%">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
          >
            <el-form-item label="公司logo：">
              <p class="size-12">建议大小204px*70px</p>
              <div>
                <img
                  class="w-100 h-100"
                  src="@/assets/images/logo.png"
                  alt=""
                />
              </div>
              <el-button type="primary">从媒体库选择企业LOGO</el-button>
            </el-form-item>
            <el-form-item label="公司名称：">
              <el-input placeholder="请输入公司名称"></el-input>
            </el-form-item>
            <el-form-item label="E-mail：">
              <el-input placeholder="请输入邮箱地址"></el-input>
            </el-form-item>
            <el-form-item label="微信号：">
              <el-input placeholder="请输入公众号名称"></el-input>
            </el-form-item>
            <el-form-item label="开关：">
              <el-switch
                class="ml-24"
                active-color="$color-1"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="pl-32" style="width: 50%">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
          >
            <el-form-item label="公众号二维码：">
              <p class="size-12">建议大小344px*344px</p>
              <div>
                <img
                  class="w-100 h-100"
                  src="@/assets/images/logo.png"
                  alt=""
                />
              </div>
              <el-button type="primary">选择公众号二维码</el-button>
            </el-form-item>
            <el-form-item label="手机/电话：">
              <el-input placeholder="请输入手机/电话号码"></el-input>
            </el-form-item>
            <el-form-item label="E-mail：">
              <el-input placeholder="请输入邮箱地址"></el-input>
            </el-form-item>
            <el-form-item label="公司地址：">
              <el-input placeholder="请输入公司地址"></el-input>
            </el-form-item>
            <el-form-item label="QQ：">
              <el-input placeholder="请输QQ号码"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div v-if="type == 13">
      <div class="flex">
        <p class="w-150 text-right">提示文字:</p>
        <el-input class="ml-16 w-300" placeholder="请输入提示文字"></el-input>
      </div>
      <div class="flex">
        <p class="w-150 text-right"></p>
        <p class="size-14 co-66 mb-32 ml-24">
          场景加载或切换时时显示的文字，代替 “加载中。。。”
        </p>
      </div>
      <div class="flex">
        <p class="w-150 text-right">默认显示:</p>
        <el-switch
          class="ml-24"
          active-color="$color-1"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
    </div>
    <div v-if="type == 14">
      <p class="size-12 plr-64 lh-24">
        图片大小建议：为保证封面的最佳展示效果，网站端建议您上传
        <span class="co-51">1600x900像素</span>
        大小或相近比例图片，移动端建议您上传
        <span class="co-51">375x667像素</span>
        大小或相近比例的图片。
        <span class="co-51">
          必须同时设置网站端和手机端的图片才可启用启动画面。
        </span>
      </p>
      <div class="flex mt-16">
        <div class="flex flex-clm" style="width: 50%">
          <p class="co-51 size-12">网站端</p>
          <div class="mt-16">
            <img
              class="w-120 h-120 ra-6"
              src="@/assets/images/image4.png"
              alt=""
            />
          </div>
          <div class="mt-16">
            <el-button type="primary">从媒体库选择</el-button>
            <el-button type="primary">移除</el-button>
          </div>
        </div>
        <div class="flex flex-clm" style="width: 50%">
          <p class="co-51 size-12">网站端</p>
          <div class="mt-16">
            <img
              class="w-120 h-120 ra-6"
              src="@/assets/images/image4.png"
              alt=""
            />
          </div>
          <div class="mt-16">
            <el-button type="primary">从媒体库选择</el-button>
            <el-button type="primary">移除</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type == 15">
      <p class="text-center mtb-16 size-12">建议图片大小：400 x 400</p>
      <div class="flex flex-center">
        <img
          src="@/assets/images/image4.png"
          alt=""
          style="max-width: 300px; height: auto"
        />
      </div>
      <div class="flex flex-center mt-16">
        <el-button type="primary">从媒体库选择</el-button>
      </div>
      <div class="plr-32 mt-32">
        <div class="flex">
          <p class="w-150 text-right">背景色:</p>
          <el-input class="ml-16 w-300" placeholder="请输入背景色"></el-input>
        </div>
        <div class="flex mt-32">
          <p class="w-150 text-right">是否开启:</p>
          <div class="ml-16">
            <el-radio v-model="radio" label="1">关闭</el-radio>
            <el-radio v-model="radio" label="2">开启</el-radio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: "1",
      selectValue: "1",
      ruleForm: {},
      rules: {},
      checked: true,
      list7: [
        {
          a1: "",
          a2: "",
        },
      ],
    };
  },
  props: ["type"],
  methods: {
    addList7() {
      this.list7.push({
        a1: "",
        a2: "",
      });
    },
    removeList7(i) {
      this.list7.splice(i, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-bg {
  width: 100%;
  background: #f7fafa;
}
</style>